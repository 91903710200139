import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import animated from 'animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import i18n from './i18n'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(ElementUI,animated,VueLazyload)

Vue.use(VueLazyload, {
  // preLoad: 1.1,             // 预加载高度比例，默认 1.3
  // loading: loadimage,     // 正在加载时图像的src
  attempt: 1,             // 尝试次数
  listenEvents: ['scroll']
});

router.afterEach((to,from, next) => {
  window.scrollTo(0,0)
})

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})