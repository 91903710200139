export default {
  aboutUs: {
    nav1: '关于我们',
    nav2: '关于我们',
    mode1: {
      title1: '广东奥飞新能源',
      cont: '<br /><br />广东奥飞新能源有限公司于2021年在中国广州成立，是A股上市公司奥飞数据（股票代码:300738）旗下控股子公司。奥飞新能源起步于中国分布式光伏市场，致力于分布式光伏项目的投资开发、建设管理、科技研发及运维服务;目前投建电站已覆盖中国十余个省份，成为近年来中国发展迅猛的工商业光伏运营商。<br /><br />2023年，奥飞新能源以新加坡为国际业务起点，全面开拓海外行业及个人用户市场；以强有力的研发团队，创新前沿的技术理念，携手行业顶尖企业，支持提供从需求分析，设计规划，建设实施，至后期营运的全生命周期、一站式绿色能源解决方案。 '
    },
    mode2: {
      title2: '业绩',
      cont1: '覆盖国家/地区',
      cont2: '太阳能发电厂',
      cont3: '容量'
    },
    mode3: {
      title3: '业务模式',
      cont1: '企业业务合作',
      cont2: '能源管理合约',
      cont3: '购电协议'
    },
    mode4: {
      title4: '资格和证书',
    },
    mode5: {
      title5: '公司愿景',
      cont1: '奥飞新能源将始终以高标准、高品质、高收益的合作理念，迎接全球双碳时代的挑战和机遇',
      cont2: '以"Aofei，powering your life"为口号',
      cont3: '积极探索新能源领域的发展机遇，推动绿色能源的应用和推广，为实现全球能源的结构升级贡献力量!'
    }
  },
  solutions: {
    residential: {
      menuPic: {
        title: '户用太阳能装置',
        cont: '拯救地球，同时释放更大的成本节约。在你的家里安装太阳能电池板、逆变器和电池。节省成本，并将剩余的电力卖回电网。',
        button: '获取免费报价'
      },
      nav1: '关于我们',
      nav2: '解决方案',
      nav3: '光伏装置',
      nav4: '户用太阳能装置',
      box1: {
        title: '你为什么要使用太阳能发电?',
        cont: '在赤道附近，全年都有充足的阳光。这使得太阳能成为这里最有前途的可再生能源选择之一。',
      },
      box2: {
        title: '收益',
        cont1: '节省电费',
        cont2: '减少碳足迹',
        cont3: '把剩余部分卖回给电网',
        cont4: '延长屋顶使用寿命',
        cont5: '房屋被动冷却',
        cont6: '提高房屋估价'
      },
      box3: {
        title: '项目开发过程',
        cont1: {
          title: '步骤 1',
          cont: '咨询与评估'
        },
        cont2: {
          title: '步骤 2',
          cont: '现场考察和尽职调查'
        },
        cont3: {
          title: '步骤 3',
          cont: '建议书审查和验收'
        },
        cont4: {
          title: '步骤 4',
          cont: '太阳能系统安装'
        },
        cont5: {
          title: '步骤 5',
          cont: '并网验收和盈利节约'
        }
      },
      box4: {
        title: '定价',
        box1: {
          title1: 'MSPPA',
          cont1: '负担得起&无麻烦',
          cont2: '售价',
          cont3: '月',
          cont4: '预付0美元，所有许可证和安装费用由供应商承担',
          cont5: '协议有效期内免费维护',
          cont6: '每个月可以节省40%以上电费',
          button: '获取免费报价'
        },
        box2: {
          title1: '前期采购',
          cont1: '预付款，拥有你的系统，通过太阳能发电赚取被动收入！',
          cont2: '价格取决于站点情况和需求',
          cont3: '立即拥有系统',
          cont4: '包括1年保修和维护保养',
          cont5: '后续维护单独安排，费用由业主承担',
          button: '获取免费报价'
        }
      }
    },
    commercial: {
      menuPic: {
        title: '工商业太阳能装置',
        cont: '拯救地球，同时更大的节约成本。在你的家里安装太阳能电池板、逆变器和电池。节省成本，并将剩余的电力卖回电网。',
        button: '获取免费报价'
      },
      nav1: '关于我们',
      nav2: '解决方案',
      nav3: '光伏装置',
      nav4: '工商业太阳能装置',
      box1: {
        cont1: '无前期成本',
        cont2: '通过低太阳能电价立即节约成本',
        cont3: '无价格波动',
        cont4: '综合运维服务'
      },
      box2: {
        title: '项目开发过程',
        cont1: {
          title: '步骤 1',
          cont: '咨询与评估'
        },
        cont2: {
          title: '步骤 2',
          cont: '现场考察和尽职调查'
        },
        cont3: {
          title: '步骤 3',
          cont: '建议书审查和验收'
        },
        cont4: {
          title: '步骤 4',
          cont: '太阳能系统安装'
        },
        cont5: {
          title: '步骤 5',
          cont: '并网验收和盈利节约'
        }
      },
      box3: {
        title: '定价',
        box1: {
          title1: 'MSPPA',
          cont1: '负担得起&无麻烦',
          cont2: '每月更高的折扣',
          cont4: '预付0美元，所有许可证和安装费用由供应商承担',
          cont5: '15-20年协议',
          cont6: '高达x%的电力折扣',
          cont7: '协议有效期内免费维护',
          cont8: '最小能量保证',
          button: ''
        },
        box2: {
          title1: '前期采购',
          cont1: '预付款，拥有你的系统，通过太阳能发电赚取被动收入！',
          cont2: '价格取决于站点情况和需求',
          cont3: '立即拥有系统',
          cont4: '包括1年保修和维护保养',
          cont5: '后续维护单独安排，费用由业主承担',
          button: '获取免费报价'
        }
      }
    },
    photovoltaic: {
      nav1: '关于我们',
      nav2: '解决方案',
      nav3: '家用光伏储能',
      box1: {
        title: '集多功能于一体 智能且高效的储能解决方案',
        cont1: '尽情享受绿色能源和节能的生活环境。',
        cont2: '通过奥飞新能源的太阳能系统，轻松获得可再生能源，体验一 流的一站式户用解决方案。 ',
        cont3: '我们为您提供安全可靠、理念先进、易于安装的能源系统。',
        cont4: '白天，SolaX储能系统将太阳能转化为家庭的"绿色”电力。',
        cont5: "停电事故发生时，SolaX储能系统无 感切换，提供备用电源功能，无惧 断电困扰，保障生活用电安全。",
        cont6: '随着夜幕降临，储能系统充分利用 白天存储的能量，为您持续提供绿色能源。'
      },
      box2: {
        cont1: {
          title: '可靠高效的系统',
          cont: '在恶劣环境下性能稳定<br />1.HALT测试<br />2.极端高温和低温试验<br />3.盐雾试验<br />4.满载老化试验'
        },
        cont2: {
          title: '快速充电',
          cont: '随着充电效率的提高，在相同的<br />时间内可以储存更多的电力'
        },
        cont3: {
          title: '2倍直流过匹配<br />1.5倍直流输入功率',
          cont: '支持高达200%的直流过匹配，<br />允许高效转换太阳能，<br />并将多余的能量存储在电池中<br />以供夜间或备用使用。'
        },
        cont4: {
          title: '无缝不间断解决方案',
          cont: '所有串联逆变器都可以连接到柴油发电机。在停电和日照条件差的情况下，柴油仍然可以用来确保电力供应。'
        },
      },
      box3: {
        title: '案例',
        case1: '菲律宾',
        case2: '越南',
        button: '更多'
      }
    },
    iac: {
      nav1: '关于我们',
      nav2: '解决方案',
      nav3: '商业和工业储能系统',
      title1: '',
      title2: '奥飞新能源针对中小型工商业，公共建筑屋顶，商超，写字楼等应用场景提供工商业解决方案。<br /><br />工商业用电量普遍较高，且屋顶有较为广阔的闲置面积。投资光伏电站是企业可以合理利用空地，并避免能源的价格日益增长的合理解决方案。不仅能节约资源，减少企业碳足迹，同时还可以降低能源成本，提高投资回报率。<br /><br />奥飞新能源提供的适用于工商业应用场景的解决方案，配备多路MPPT，每路最大转换效率高达99%，即使复杂的应用场景也不会影响正常发电。IP66的防护等级，交直流二级防雷，可选的智能拉弧，使用稳定能源的同时也确保企业用电安全。',
      box1: {
        cont1: '可以建在建筑物的屋顶或空地上，形成自给自足的发电厂。',
        cont2: '所有逆变器数据都可以在奥飞能源云平台APP上进行监控。',
        cont3: '将所有工商业光伏逆变器连接到DataHub 1000上的3个可用RS485端口后，整个电厂的输出/输入功率可以根据现场需求进行设置和控制。'
      },
      box2: {
        cont1: '最多支持',
        cont2: '台逆变器',
        cont3: '兼容的光伏逆变器',
        cont4: '设备',
        unit: '60'
      },
      box3: {
        cont1: '每个端口都可以',
        cont2: '与多达20个逆变器进行',
        cont3: '级联试串联通信',
        cont4: '每1台DataHub 1000可连接60台逆变器',
        cont5: '与智能仪表交互，如',
        cont6: 'DTSU666-CT。'
      },
      box4: {
        title: '案例',
        case1: '',
        case2: '广州',
        case3: '梅州',
        button: '更多'
      }
    },
    microGrid: {
      nav1: '关于我们',
      nav2: '解决方案',
      nav3: '太阳能微电网系统',
      title: '柴油发电机伴侣',
      cont1: '关于“柴油发电机伴侣”',
      cont2: '奥飞试图通过“柴油发电机伴侣”实现“柴油发电机的2.0时代”，通过基于可变负荷使用量优化能源消耗来提高效率。这种环境保护工作帮助企业实现直接的成本节约，同时减少柴油的使用、噪音污染和碳排放。',
      box1: {
        title: '强适用性',
        cont1: '没有电力供应',
        cont2: '不稳定电源',
        cont3: '柴油价格高',
        cont4: '噪音敏感环境'
      },
      box2: {
        title: '操作模式',
        cont1: {
          title: '小规模',
          cont: '农场/建筑工地',
        },
        cont2: {
          title: '中等规模',
          cont: '岛屿',
        },
        cont3: {
          title: '大规模',
          cont: '欠发达地区的矿山/工厂',
        }
      }
    },
    case: {
      nav1: '关于我们',
      nav2: '案例',
      tip1: '装机容量',
      tip2: '投资金额',
      tip3: '年发电量',
      tip4: '节约标准煤',
      tip5: '每年减少二氧化碳排放量',
      tip6: '容量',
      country1: '中国',
      country2: '新加坡',
      country3: '菲律宾',
      country4: '越南',
      country5: '印度尼西亚',
      country6: '巴基斯坦',
      country7: '泰国曼谷',
      country8: '印度',
      box1: {
        title: '商业太阳能装置',

        cont1: {
          address: '广东省广州市',
          company: '中船黄埔文冲船舶有限公司'
        },
        cont2: {
          address: '广东省梅州市',
          company: '梅州伟友耐磨材料有限公司'
        },
        cont3: {
          address: '广东省江门市',
          company: '广东玫瑰岛家居股份有限公司'
        },
        cont4: {
          address: '广东省揭阳市',
          company: '普宁市荣茂隆印染有限公司'
        },
        cont5: {
          address: '广东省东莞市',
          company: '广东旺盈环保包装实业有限公司'
        },
        cont6: {
          address: '广东省廊坊市',
          company: '廊坊市讯云数据科技有限公司'
        },
        cont7: {
          address: 'Singapore',
          company: '5 Kallang Way 2A Lubritrade Centre Singapore 347494.'
        },
        cont8: {
          address: 'Singapore',
          company: '36 Tuas Ave 8 Singapore 639250.'
        }
      },
      box2: {
        title: '住宅太阳能装置',
        cont9: {
          address: 'Singapore',
          company: "53 St Patrick's Rd Singapore 424168."
        }
      },
      box3: {
        title: '储能系统',
        cont10: {
          address: 'X1-SMART',
          company: "Temple drive,Greenmeadows subdivision,Quezon city"
        },
        cont11: {
          address: 'X3-MEGA G2',
          company: "Long An,Long Thanh,Dong Nai Province,Vietnam"
        },
        cont12: {
          address: 'X3-PRO G2',
          company: "Awis Shalim Bali,Indonesia"
        },
        cont13: {
          address: 'X3-FORTH',
          company: "Kohat,Khyber Pakhtunkhwa,Pakistan"
        },
        cont14: {
          address: 'X3-MIC',
          company: "Bangkok"
        },
        cont15: {
          address: 'X3-PRO G2',
          company: "Harsh Industry,Khatraj Gidc,Dist-Gandhinagar,Gujarat"
        },
      }
    },
    contactUs: {
      nav1: '关于我们',
      nav2: '联系我们',
      box1: {
        cont1: {
          title: '地址',
          intro: '广州市天河区华城大道68号环球都会广场4507号',
          intro1: '5 Kallang Way 2A #06-08 Lubritrade Centre Singapore 347494'
        },
        cont2: {
          title: '邮箱',
        },
        cont3: {
          title: '电话',
        },
        cont4: {
          title: '网址',
        }
      },
      box2: {
        title: '给我们发信息',
        tip: '通过下面的表格与我们的团队和顾问联系',
        label1: '你的名字',
        label2: '邮箱地址',
        label3: '电话号码',
        label4: '营业地址',
        label5: '选择国家',
        label6: '消息',
        button1: '发送',
        button2: '',
      }
    },
    footer: {
      title1: '工作时间',
      title2: '联系我们',
      cont1: '广东奥飞新能源',
      cont2: '广州市天河区华城大道68号环球都会广场4507号',
      cont3: 'Aofei New Energy SG Pte. Ltd.',
      cont4: '5 Kallang Way 2A #06-08 Lubritrade Centre Singapore 347494'
    },
    menu: {
      title1: '关于我们',
      title2: '解决方案',
      title3: '光伏装置',
      title4: '户用太阳能装置',
      title5: '工商业太阳能装置',
      title6: '家用光伏储能',
      title7: '商业和工业储能系统',
      title8: '太阳能微电网系统',
      title9: '案例',
      title10: '联系我们',
    }
  }
}