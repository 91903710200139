<template>
    <el-carousel height="560px" :interval="4000" arrow="always" class="banners">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img class="wow animate fadeIn" data-wow-duration="3s" :src="(item.url)" alt="">
      </el-carousel-item>
    </el-carousel>
</template>

<script>
// var img1 = require('@/assets/images/img10.jpg')
var img2 = require('@/assets/images/img25.jpg')
var img3 = require('@/assets/images/img23.png')
var img4 = require('@/assets/images/img24.jpg')
var img5 = require('@/assets/images/img22.jpg')

import { WOW } from "wowjs";
export default {
  data() {
    return {
      bannerList: [
        // {id:1,url:img1},
        {id:2,url:img3},
        {id:3,url:img2},
        {id:4,url:img5},
        {id:5,url:img4},
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-carousel__button{
    height: 5px;
    background: linear-gradient(0deg, rgb(222, 154, 79), #ffb615);
  }
  .banners{
    // background-color: aqua;
    img{
      width: 100%;
      height: 560px;
      object-fit: cover;
    }
    ::v-deep .el-icon-arrow-right:before{
      content: "\e6e0"!important;
    }
  }
</style>

