
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
import outLayout from '@/views/Layout'
// 创建路由实例
const router = new VueRouter({
  routes: [
    {
      path: '/login',
      component: () => import('../views/login/index'),
      hidden: true
    },
    {
      path: '/admin',
      component: () => import('../views/admin/index'),
      hidden: true
    },
    {
      path: '*',
      component: () => import('@/views/404'),
      hidden: true
    },
    {
      path: '/',
      component: outLayout,
      redirect: '/aboutUs',
      children: [
      // {
      //   path: 'home',
      //   name: 'Home',
      //   component: () => import('../views/home/index'),
      //   meta: { title: '首页', icon: 'dashboard' },
      //   hidden: true
      // },
      {
        path: 'aboutUs',
        name: 'AboutUs',
        component: () => import('../views/home/aboutUs/index'),
        meta: { title: '关于我们', icon: 'dashboard' },
        hidden: true
      },{
        path: 'solutions',
        name: 'Services',
        component: () => import('../views/home/solutions/index'),
        meta: { title: '住宅太阳能装置', icon: 'dashboard' },
        hidden: true
      },{
        path: 'solutions/commercial',
        name: 'Services',
        component: () => import('../views/home/solutions/commercial'),
        meta: { title: '商业太阳能装置', icon: 'dashboard' },
        hidden: true
      },{
        path: 'solutions/photovoltaic',
        name: 'Photovoltaic',
        component: () => import('../views/home/solutions/photovoltaic'),
        meta: { title: '家用光伏储能', icon: 'dashboard' },
        hidden: true
      },{
        path: 'solutions/iac',
        name: 'iac',
        component: () => import('../views/home/solutions/iac'),
        meta: { title: '工商业储能系统', icon: 'dashboard' },
        hidden: true
      },{
        path: 'solutions/microGrid',
        name: 'MicroGrid',
        component: () => import('../views/home/solutions/microGrid'),
        meta: { title: '太阳能微电网系统', icon: 'dashboard' },
        hidden: true
      },{
        path: 'product',
        name: 'SolarPanels',
        component: () => import('../views/home/product/index'),
        meta: { title: '太阳能电池板', icon: 'dashboard' },
        hidden: true
      },{
        path: 'product/Inverters',
        name: 'Inverters',
        component: () => import('../views/home/product/Inverters'),
        meta: { title: '逆变器', icon: 'dashboard' },
        hidden: true
      },{
        path: 'product/Batteries',
        name: 'Batteries',
        component: () => import('../views/home/product/Batteries'),
        meta: { title: '电池', icon: 'dashboard' },
        hidden: true
      },{
        path: 'product/PV',
        name: 'PV',
        component: () => import('../views/home/product/PV'),
        meta: { title: '光伏组件', icon: 'dashboard' },
        hidden: true
      },{
        path: 'product/details',
        name: 'Details',
        component: () => import('../views/home/product/details'),
        meta: { title: '产品详情', icon: 'dashboard' },
        hidden: true
      },{
        path: 'FAQs',
        name: 'FAQs',
        component: () => import('../views/home/FAQs/index'),
        meta: { title: '解决方案', icon: 'dashboard' },
        hidden: true
      },
      {
        path: 'cases',
        name: 'Cases',
        component: () => import('../views/home/cases/index'),
        meta: { title: '案例', icon: 'dashboard' },
        hidden: true
      },{
        path: 'FAQs',
        name: 'FAQs',
        component: () => import('../views/home/FAQs/index'),
        meta: { title: '问答', icon: 'dashboard' },
        hidden: true
      },{
        path: 'articles',
        name: 'Articles',
        component: () => import('../views/home/articles/index'),
        meta: { title: '文章', icon: 'dashboard' },
        hidden: true
      },{
        path: 'contact',
        name: 'ContactUs',
        component: () => import('../views/home/contactUs/index'),
        meta: { title: '联系我们', icon: 'dashboard' },
        hidden: true
      }
    ]
    }
  ]
});



router.beforeEach((to,from, next) => {
  console.log(to.path)
  if(to.path=='/admin'){
    // 校验登录状态
    var token = localStorage.getItem('token');
    if (!token) {
      next({
        path:"/login",      
        query:{redirect: to.path} 
      })
    }else {
      next()
    }
  }else {
    next()
  }

})
export default router