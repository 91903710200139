<template>
  <div class="Header">
    <div class="Header-black flexSp" :class="[isAnimate,isWhiteBg]" data-wow-iteration="infinite">
      <div class="box-center">
        <div class="Header-logo" @click="$router.push('/aboutUs')" >
          <img v-show="isWhiteLogo" class="login-Pc" v-lazy="require('@/assets/images/logo1-pc.png')" height="40">
          <img v-show="!isWhiteLogo" class="login-Pc" v-lazy="require('@/assets/images/logo.png')" height="40">
        </div>
        <div class="flexAlign">
          <div class="Header-menu">
            <Menu />
          </div>
        </div>
      </div>
      <div class="lang"  @click="selectLang">
        <div style="color:#ccc">{{currentLang}}</div>
        <img v-lazy="require('@/assets/images/lang.png')">
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './menu'
import { WOW } from "wowjs";
const langTitle = navigator.language == 'en' ? '中文' : 'English';
const lang = navigator.language 

export default {
  components: {
    Menu
  },
  data() {
    return {
      isAnimate: '',
      isWhiteBg: '',
      isWhiteLogo: true,
      currentLang: localStorage.getItem('lang')==''? langTitle : (localStorage.getItem('lang')=='en'? '中文' : 'English')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const distanceToTop = window.scrollY;
      if(distanceToTop < 80) {
        this.isAnimate = ''
        this.isWhiteBg = ''
        this.isWhiteLogo = true
      }else {
        this.isWhiteBg = 'whiteBg'
        this.isAnimate = 'animated fadeInDown'
        this.isWhiteLogo = false
      }
    },
    selectLang() {
      if(localStorage.getItem('lang') == 'en') {
			  this.$i18n.locale = 'zh'
        localStorage.setItem('lang','zh'); // 这样页面刷新就不会改变选择的语言了

      }else if(localStorage.getItem('lang') == 'zh'){
			  this.$i18n.locale = 'en'
        localStorage.setItem('lang','en'); // 这样页面刷新就不会改变选择的语言了
      }else {
        console.log(lang,langTitle)
        if(langTitle == '中文') {
		  	  this.$i18n.locale = 'English'
          localStorage.setItem('lang','zh')
        }else if(langTitle == 'English') {
		  	  this.$i18n.locale = '中文'
          localStorage.setItem('lang','en')
        }
      }
      this.$router.push({
        path: '/aboutUs'
      })
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.Header {
  margin-bottom: 80px;
  .lang {
    position: absolute;
    right: 40px;
    top: 40;
    cursor: pointer;
    display: flex;
    div {
      margin-top: 8px;
      margin-right: 10px;
    }
  }
}
.Header-black{
  position: relative;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 20;
  border-bottom: 1px;
  background-color: rgba(17, 17, 17);
  .box-center{
    display: flex;
    justify-content: space-around;
  }
  .Header-logo{
    padding-top: 10px;
    cursor: pointer;
  }
}
.whiteBg {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}



@media screen and (max-width: 830px) {
 .Header .lang div {
   display: none;
 }
}
@media screen and (max-width: 550px) {
  .Header .lang {
    right: 10px;
  }
}
@media screen and (max-width: 460px) {
  .Header .lang {
    right: 70px;
  }
}
</style>